<template>
  <div class="section-wrapper">
    <breadcumb />
    <div class="certificate-wrapper">
      <body-card>
        <template v-slot:headerTitle>
<!--          <div class="d-flex justify-content-between">-->
            <h4 class="card-title">{{ $t('teaGardenPduService.certificate_view') }}</h4>
<!--          </div>-->
        </template>
        <template v-slot:headerAction>
          <div class="text-right">
            <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
              <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
            </b-button> &nbsp;&nbsp;
          </div>
        </template>
        <template v-slot:body>
          <b-overlay :show="loading">
            <div style="overflow: auto;" id="printMe">
              <div class="certificate-body" style="font-family: Poppins, sans-serif;margin: auto;padding: 70px 30px 30px;background: #CACACA;width: 600px;text-align: center;">
                <img id="bg-image" src="../../../../../assets/images/btb.png" style="width: 224px;position: absolute;top: 55%;left: 50%;transform: translate(-50%, -50%);opacity: 0.2;z-index: 1;" alt="">
                <img src="../../../../../assets/images/btb.png" width="70" alt="">
                <h5 style="margin: 8px 0 0;font-weight: 700;text-transform: uppercase;">Bangladesh Tea Board</h5>
                <P style="margin: 8px 0;font-size: 14px;font-weight: 700;">PROJECT DEVELOPMENT UNIT</P>
                <h6 style="margin-bottom: 30px;font-weight: 700;">MANAGEMENT TRAINING CENTER</h6>
                <p class="BRUSHSCI" style="font-family: 'BRUSHSCI', cursive;font-size: 18px;font-weight: 700;">Hereby Confers the</p>
                <h2 class="PalaceScriptMT" style="font-family: 'PalaceScriptMT', cursive;font-weight: 600;font-size: 49px;color: #484D69;position: relative;z-index: 10;">{{ detailsData.course ? detailsData.course.course_name_en : '' }}</h2>
                <h3 class="PalaceScriptMT" style="font-family: 'PalaceScriptMT', cursive;font-weight: 600;font-size: 42px;color: #484D69;line-height: 0;margin-bottom: 50px;position: relative;z-index: 10;">upon</h3>
                <h4 class="MonotypeCorsiva" style="font-family: 'MonotypeCorsiva', cursive;font-size: 29px;font-weight: 700;color: #383838;margin-bottom: 5px;">{{ detailsData.applicant_name_en }}</h4>
                <p class="BRUSHSCI" style="font-family: 'BRUSHSCI', cursive;font-size: 17px;font-weight: 600;margin-bottom: 10px;line-height: 1.5;margin-bottom: 15px;">having successfully completed the {{ detailsData.circular ? detailsData.circular.batch_no : '' }} <sup>th</sup> Diploma course and secured {{ detailsData.grade_point }} out of Grade Point {{ detailsData.course ? detailsData.course.grade_point : '' }} in the field of Tea Management at Management Training Center, Project Development Unit of Bangladesh Tea Board, Srimongal, Moulvibazar.</p>
                <table style="width:100%;font-size: 12px;font-weight: 500;">
                  <tr>
                    <td style="text-align: left;width: 40%;vertical-align: bottom;">Date : {{ detailsData.certificate_issue_date | dateFormatEn }}</td>
                    <td style="text-align: center;width: 60%;" v-if="documentSignatory && signatory_user_info">
                      <img v-if="documentSignatory.show_signature && signatory_user_info.signature" :src="authServiceBaseUrl + 'storage/' + signatory_user_info.signature" width="130" alt="">
                      <p>{{ signatory_user_info.text_en }}</p>
                      <p>{{ signatory_user_info.designation_en }}</p>
                      <p>Bangladesh Tea Board</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-overlay>
        </template>
      </body-card>
    </div>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { applicantInfoShowApi } from '../../api/routes'
export default {
  name: 'Certificate',
  props: ['id'],
  components: {},
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      authServiceBaseUrl: authServiceBaseUrl,
      valid: null,
      detailsData: {
      },
      detailsItemId: '',
      dataUrl: null,
      documentSignatory: null,
      approve_user_info: {},
      signatory_user_info: null
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  created () {
      this.getFormData()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    async getFormData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, applicantInfoShowApi + '/' + parseInt(this.$route.params.id), { show_signatory_info: 1 })
        if (result.success) {
          this.detailsData = Object.assign({}, this.detailsData, result.data)
          if (result.document_signatory) {
            this.documentSignatory = result.document_signatory
            this.getSignatoryUserInfo(result.document_signatory.user_id)
          } else {
            this.$store.dispatch('mutateCommonProperties', { loading: false })
          }
        } else {
          this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId, show_certificate: 1 })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 17)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, applicantInfoShowApi + '/' + parseInt(this.$route.params.id), params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async getSignatoryUserInfo (id) {
      const result = await RestApi.getData(authServiceBaseUrl, '/user-management/user/get-user-details/' + id)
      if (result.success) {
        this.signatory_user_info = result.data
        if (this.signatory_user_info) {
            this.getDesignationNameSignatory(this.signatory_user_info.designation_id)
            this.getOfficeNameSignatory(this.signatory_user_info.office_id)
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    async getApproveUserInfo (id) {
        const result = await RestApi.getData(authServiceBaseUrl, '/user-management/user/get-user-details/' + id)
        if (result.success) {
          this.approve_user_info = result.data
          if (this.approve_user_info) {
             this.getDesignationNameApprove(this.approve_user_info.designation_id)
             this.getOfficeNameApprove(this.approve_user_info.office_id)
          }
        }
    },
    getDesignationNameSignatory (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      if (obj) {
        this.signatory_user_info.designation_en = obj.text_en
        this.signatory_user_info.designation_bn = obj.text_bn
      }
      return true
    },
    getOfficeNameSignatory (id) {
      const obj = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === parseInt(id))
      if (obj) {
        this.signatory_user_info.office_en = obj.text_en
        this.signatory_user_info.office_bn = obj.text_bn
      }
      return true
    },
    getDesignationNameApprove (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      if (obj) {
        this.approve_user_info.designation_en = obj.text_en
        this.approve_user_info.designation_bn = obj.text_bn
      }
      return true
    },
    getOfficeNameApprove (id) {
      const obj = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === parseInt(id))
      if (obj) {
        this.approve_user_info.office_en = obj.text_en
        this.approve_user_info.office_bn = obj.text_bn
      }
      return true
    }
  }
}
</script>
